import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Typewriter from './Typewriter';
import logo from '../assets/images/criospulse.webp';
import poster1 from '../assets/images/poster1.webp';
import poster2 from '../assets/images/poster2.webp';
import myImage from '../assets/images/hero.webp';
import myImage2 from '../assets/images/bg-droite-scaled.webp';
// start section picture
import structure from '../assets/images/Structure.webp';
import conception from '../assets/images/Conception.webp';
import exploration from '../assets/images/Exploration.webp';
import integration from '../assets/images/Integration.webp';
import maintenance from '../assets/images/Maintenance.webp';
import optimisation from '../assets/images/Optimisation.webp';
import miseEnLigne from '../assets/images/MiseEnLigne.webp';
import lancement from '../assets/images/Lancement.webp';
// end section picture
import ModalEn from './ModalEn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';
import { faLink, faCheck, faPaintBrush, faCompass ,faChartLine,faPlay,faWrench, faLaptopCode, faSearch, faMobileAlt, faHeadset, faLightbulb, faDollarSign, faTools, faRocket } from '@fortawesome/free-solid-svg-icons';
import { Timeline, Bookmark } from "../vertical-progress-timeline";
import "../vertical-progress-timeline/style.css";
import ReCAPTCHA from 'react-google-recaptcha';
import FooterEn from "./FooterEn";

const movies = [
    {
      title: "Crios Consulting",
      description: "Management of administrative and outsourcing procedures",
      poster: poster1,
      url: "https://criosconsulting.com",
    },
    {
      title: "Dedravo Express",
      description: "Parcel delivery between Cotonou and Paris and online shopping on all European sites",
      poster: poster2,
      url: "https://dedravoexpress.com",
    },

];

const typeWriter = ["Collaborative", "Creative", "Affordable", "Professionnal"];

const HamburgerMenu = ({ onToggle }) => {
    return (
        <div className="absolute top-4 right-4 z-20 cursor-pointer mt-3" onClick={onToggle}>
            <div className="bg-white h-1 w-10 mb-2"></div>
            <div className="bg-white h-1 w-10 mb-2 mr-2 ml-2"></div>
            <div className="bg-white h-1 w-10"></div>
        </div>
    );
};

const packs = [
    {
      title: 'Pack STARTER',
      price: '150 000',
      currency: ' FCFA',
      price2: '230',
      currency2: ' €',
      duration: '',
      advantages: [
        'One big page',
        '5 royalty-free photos',
        'Traffic analysis and tracking (Google Analytics, etc.)',
        'Stability guarantee',
        'Security updates',
        'Annual reports',
      ],
    //   maintenance: 'Maintenance : 100 000 Fcfa / year',
    },
    {
      title: 'Pack PRO',
      price: '800 000',
      currency: ' FCFA',
      price2: '1260',
      currency2: ' €',
      duration: '',
      advantages: [
        'About 5 pages',
        '7 royalty-free photos',
        'Traffic analysis and tracking (Google Analytics, etc.)',
        'Regular backups',
        'Guaranteed stability',
        'Security updates',
       ' Monthly reports',
      ],
      maintenance: 'Maintenance : 200 000 Fcfa / year',
    },
    {
      title: 'Pack PREMIUM',
      price: '1 200 000',
      currency: ' FCFA',
      price2: '1890',
      currency2: ' €',
      duration: '',
      advantages: [
        'About 6-10 pages + blog',
        '10 royalty-free photos',
        'Traffic analysis and tracking (Google Analytics, etc.)',
        'Regular backups',
        'Stability guarantee',
        'Security updates',
        'Monthly reports',
        'Google position monitoring',
      ],
      maintenance: 'Maintenance : 300 000 Fcfa / year',
    },
  ];

const Hero = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(prev => !prev);
    };


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        whatsapp: '',
        subject: '',
        message: '',
    });
    const [flashMessage, setFlashMessage] = useState('');
    const [captchaToken, setCaptchaToken] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!captchaToken) {
            alert('Veuillez compléter la vérification reCAPTCHA.');
            return;
        }

        try {
            const response = await fetch('https://criospulse.com/app/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de l\'envoi des données');
            }

            const data = await response.json();
            // alert(data.firstName);
            setFlashMessage(data.flash_message);
            // Réinitialiser le formulaire si nécessaire
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                whatsapp: '',
                subject: '',
                message: '',
            });
            setCaptchaToken(null);
        } catch (error) {
            setFlashMessage('Une erreur est survenue : ' + error.message);
        }
    };


    const handleCaptcha = (token) => {
        setCaptchaToken(token);
    };

    return (
        <div className="relative h-screen bg-cover bg-center" style={{ backgroundImage: `url(${myImage})` }}>
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <HamburgerMenu onToggle={toggleMenu} />
            <div className="flex flex-col items-center justify-center h-full relative z-10 text-white">
                <div className="flex flex-col items-center justify-center mx-4">
                        <img src={logo} alt="Logo" className="mb-4 h-48 md:h-54"/>
                        <div className="text-4xl md:text-5xl text-center">
                            <h1 className="text-5xl md:text-6xl font-bold mb-4">
                                Web Agency{" "}
                                <span className="text-custom-yellow">
                                    <Typewriter data={typeWriter} />
                                </span>
                            </h1>
                        </div>
                </div>

                <p className="text-3xl mt-4 md:text-2xl ml-4">Design exceptional websites at controlled costs</p>

                <a href="https://criospulse.com/app/order?packRef=A1B2C" style={{ marginTop: '20px', textDecoration: 'none' }}>
                    <button
                        style={{
                        backgroundColor: '#e30f0f',
                        color: '#fff',
                        fontSize: '20px',
                        padding: '20px 40px',
                        borderRadius: '40px',
                        border: 'none',
                        cursor: 'pointer',
                        fontWeight: '1300',
                        textTransform: 'uppercase',
                        letterSpacing: '0.5px',
                        animation: 'blink 1.5s infinite', // Animation clignotante
                        }}
                    >
                        Access the Flash Sale
                    </button>
                </a>


                <a href="#home" className="ml-4 inline-block animate-bounce ">
                    <div className="flex items-center justify-center w-16 h-16 border-4 border-custom-yellow rounded-full mt-8 mb-8">
                        <FontAwesomeIcon icon={faLongArrowAltDown} className="text-custom-yellow h-8" />
                    </div>
                </a>
            </div>
            <ModalEn isOpen={menuOpen} onClose={toggleMenu} />

            {/* Section Agence Web */}
            <section id="home" className="flex items-center justify-center py-10" style={{ backgroundImage: `url(${myImage2})` }}>
                <div className="text-center container px-4">
                    <h2 className="text-4xl font-bold mt-8 text-custom-blue">What are the advantages of having us create your website?</h2>
                    <p className="mt-4 text-xl mx-auto text-justify">
                    When you call on our agency to create your website, you benefit from cutting-edge expertise and personalized support. Our team of passionate professionals offers a wide range of skills in design, development and marketing. We believe in creating tailor-made solutions, adapted to the specific needs of each customer. Thanks to our collaborative approach, we take the time to understand your goals and vision, so we can deliver a site that perfectly reflects your brand identity.<br/>                        <br /><br />
                    What's more, we guarantee ongoing search engine optimization, which is essential for ensuring your site's visibility. Our responsive designs ensure a smooth user experience on all devices, whether desktop or smartphone. When you choose our agency, you also benefit from responsive after-sales support, allowing you to concentrate on your business while we keep your site up to date and performing at its best. With us, you get not just a website, but a true partner in your digital success.
                    </p>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-8">
                        <div className="flex flex-col items-center icon-animation">
                            <FontAwesomeIcon icon={faLaptopCode} className="text-custom-yellow h-16" />
                            <h3 className="mt-2 text-lg font-semibold">Custom development</h3>
                        </div>
                        <div className="flex flex-col items-center icon-animation">
                            <FontAwesomeIcon icon={faSearch} className="text-custom-yellow h-16" />
                            <h3 className="mt-2 text-lg font-semibold">Optimized SEO</h3>
                        </div>
                        <div className="flex flex-col items-center icon-animation">
                            <FontAwesomeIcon icon={faMobileAlt} className="text-custom-yellow h-16" />
                            <h3 className="mt-2 text-lg font-semibold">Responsive Design</h3>
                        </div>
                        <div className="flex flex-col items-center icon-animation">
                            <FontAwesomeIcon icon={faHeadset} className="text-custom-yellow h-16" />
                            <h3 className="mt-2 text-lg font-semibold">Customer support</h3>
                        </div>
                        <div className="flex flex-col items-center icon-animation">
                            <FontAwesomeIcon icon={faLightbulb} className="text-custom-yellow h-16" />
                            <h3 className="mt-2 text-lg font-semibold">Innovative Solutions</h3>
                        </div>
                        <div className="flex flex-col items-center icon-animation">
                            <FontAwesomeIcon icon={faDollarSign} className="text-custom-yellow h-16" />
                            <h3 className="mt-2 text-lg font-semibold">Competitive rates</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section id="sites" class="bg-custom-gray py-16" >
                <h2 className="text-4xl font-bold mt-8 text-custom-blue text-center mb-12">Explore our <span className="text-custom-yellow">outstanding achievements</span></h2>
                <div className="align-items-center">
                    <div className="wrapper">
                    {movies.map((movie, index) => (
                        <div className="card" key={index}>
                        <div className="poster">
                            <img src={movie.poster} alt={movie.title} />
                        </div>
                        <div className="details">
                            <h1>{movie.title}</h1>

                            <p className="desc">{movie.description}</p>
                            <div className="cast">
                               <a key={index} href={movie.url} target="_blank" rel="noopener noreferrer">
                                    <button className="border-2 border-custom-yellow rounded-lg py-2 px-4 bg-transparent text-custom-yellow flex items-center text-lg">
                                        <FontAwesomeIcon icon={faLink} className="mr-2" />
                                        View the site
                                    </button>
                                </a>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>

                    <div className="text-center mt-4">
                        <a href="#methodologie">
                            <button className="mt-8 rounded-3xl py-2 px-4 bg-custom-blue font-bold text-custom-yellow text-lg
                                hover:bg-custom-yellow hover:text-custom-blue transition-colors duration-300">
                                How does Crios Pulse work?
                            </button>
                        </a>
                    </div>
                </div>
            </section>

            <section id="methodologie" class="bg-custom-gray py-16" >
                <h2 className="text-4xl font-bold mt-8 text-custom-blue text-center mb-12">Discover our <span className="text-custom-yellow"> website design methodology</span></h2>
                <div className="align-items-center">
                    <Timeline className="my-vertical-progress">
                   <Bookmark data-aos="flip-down" icon={<FontAwesomeIcon icon={faSearch} className="timeline-icone" />}>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">Needs analysis</h1>
                        <p className="text-xl">To design a website, it's essential to clearly define your expectations. This starts with a meeting to identify your needs. We'll discuss the services required, the scope of the project, its theme, the target audience, deadlines, restrictions and competitors, among other things.</p>
                    </Bookmark>
                    <Bookmark data-aos="flip-down" data-aos-offset="100" data-aos-delay="100" icon={<FontAwesomeIcon icon={faLightbulb} className="timeline-icone"/>}>
                        <div className="marginPicOnDesktop hidden md:block" style={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={structure} className="w-60" style={{ width: '80%' }} alt="structure"/>
                        </div>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">Content structure</h1>
                        <p className="text-xl">Once the requirements have been clearly defined, CriosPulse carefully examines the documentation provided by the Customer. Graphic charter, description of services, press articles, all elements are studied and analyzed by CriosPulse, which then proposes a content tree structure accompanied by its recommendations.</p>
                    </Bookmark>
                    <Bookmark data-aos="flip-down" data-aos-offset="100" data-aos-delay="100" icon={<FontAwesomeIcon icon={faPaintBrush} className="timeline-icone"/>}>
                        <div className="marginPicOnDesktop hidden md:block" style={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={conception} className="w-60" style={{ width: '80%' }} alt="conception"/>
                        </div>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">Designing the model</h1>
                        <p className="text-xl">CriosPulse presents the Customer with several examples of responsive design website templates, adapted to the sector of activity and type of website desired. The Customer chooses the model that suits him best, expressing his preferences for the layout of the elements.</p>
                    </Bookmark>
                    <Bookmark data-aos="flip-down" data-aos-offset="100" data-aos-delay="100" icon={<FontAwesomeIcon icon={faTools} className="timeline-icone" />}>
                        <div className="marginPicOnDesktop hidden md:block" style={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={miseEnLigne} className="w-60" style={{ width: '80%' }} alt="miseEnLigne"/>
                        </div>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">On-line publishing</h1>
                        <p className="text-xl">Once the customer's crucial choices have been confirmed, CriosPulse begins the technical installation of the site. This includes the domain name, hosting, e-mail addresses, SSL certificate, database, content management system, security and maintenance modules. At the end of this stage, the e-mail addresses are operational and the site displays the customer's logo with the words “site under construction”.</p>
                    </Bookmark>
                    <Bookmark data-aos="flip-down" data-aos-offset="100" data-aos-delay="100" icon={<FontAwesomeIcon icon={faRocket} className="timeline-icone" />} >
                        <div className="marginPicOnDesktop hidden md:block" style={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={integration} className="w-60" style={{ width: '80%' }} alt="integration"/>
                        </div>
                    <h1 className="text-3xl mb-2 font-bold text-custom-blue">Integration</h1>
                    <p className="text-xl">Integration is the phase during which CriosPulse gives the empty site the look and feel it was chosen for when the layout was selected. During this stage, the content (text, images, etc.) is formatted to create a harmonious, easy-to-navigate whole.</p>
                    </Bookmark>
                    <Bookmark data-aos="flip-down" data-aos-offset="100" data-aos-delay="100" icon={<FontAwesomeIcon icon={faCompass} className="timeline-icone" />}>
                        <div className="marginPicOnDesktop hidden md:block" style={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={exploration} className="w-60" style={{ width: '80%' }} alt="exploration"/>
                        </div>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">Exploration</h1>
                        <p className="text-xl">Once the integration is complete, CriosPulse creates a secure access for the Customer, allowing him to explore the website. This allows them to familiarize themselves with the tool, and to provide feedback to CriosPulse for possible adjustments to content or design.</p>
                    </Bookmark>
                    <Bookmark data-aos="flip-down" data-aos-offset="100" data-aos-delay="100" icon={<FontAwesomeIcon icon={faChartLine} className="timeline-icone"/>}>
                        <div className="marginPicOnDesktop hidden md:block" style={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={optimisation} className="w-60" style={{ width: '80%' }} alt="optimisation"/>
                        </div>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">Optimization</h1>
                        <p className="text-xl">To ensure a website's good positioning on search engines, it's crucial to pay specific attention to its SEO optimization. This starts with technical improvements (code structure, loading speed, page size, image compression, etc.), followed by content optimization (titles, keywords, meta tags, etc.).</p>
                    </Bookmark>
                    <Bookmark data-aos="flip-down" data-aos-offset="100" data-aos-delay="100" icon={<FontAwesomeIcon icon={faPlay} className="timeline-icone"/>}>
                        <div className="marginPicOnDesktop hidden md:block" style={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={lancement} className="w-60" style={{ width: '80%' }} alt="lancement"/>
                        </div>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">Launch</h1>
                        <p className="text-xl"> A date is set for the official inauguration of the website. It becomes accessible to the public and is submitted to search engines for referencing. A promotional announcement is published on the Criospulse Facebook page, including a link to the site. Finally, a sitemap is sent to Google to facilitate indexing.</p>
                    </Bookmark>
                    <Bookmark data-aos="flip-down" data-aos-offset="100" data-aos-delay="100" icon={<FontAwesomeIcon icon={faWrench} className="timeline-icone" />}>
                        <div className="marginPicOnDesktop hidden md:block" style={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={maintenance} className="w-60" style={{ width: '80%' }} alt="maintenance"/>
                        </div>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">Maintenance</h1>
                        <p className="text-xl">To ensure the reliability of the website and the protection of its users, Criospulse offers a regular technical and security maintenance service.</p>
                    </Bookmark>

                    </Timeline>
                </div>

                <div className="text-center mt-4">
                    <a href="#tarifs">
                        <button className="mt-8 rounded-3xl py-2 px-4 bg-custom-blue font-bold text-custom-yellow text-lg
                            hover:bg-custom-yellow hover:text-custom-blue transition-colors duration-300">
                            What are the different packs?
                        </button>
                    </a>
                </div>
            </section>

            <section id="tarifs" className="bg-custom-gray mx-auto px-4 py-8">
                <div className="relative">
                    <h2 className="text-4xl font-bold mt-4 mb-8 text-custom-blue text-center z-10">
                    See our different <span className="text-custom-yellow">packs</span>
                    </h2>
                </div>

                <div className=" container p-6 mx-auto my-6 bg-white rounded-lg shadow-lg overflow-hidden">
                    <h2 className="text-2xl font-bold text-left text-[#29426d] mb-4">What are the main services included in Crios Pulse packages?</h2>
                    <ul className="list-none space-y-2">
                        <li className="text-lg">
                            <FontAwesomeIcon icon={faCheck} className="text-custom-yellow mr-2" />
                            <strong>Complete website </strong>: domain name, hosting, and email accounts.
                        </li>
                        <li className="text-lg">
                            <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                            <strong>Responsive design </strong>: perfectly suited to all devices, including mobile.                        </li>
                        <li className="text-lg">
                            <FontAwesomeIcon icon={faCheck} className="text-custom-yellow mr-2" />
                            <strong>Optimized SEO (natural search engine optimization)</strong>: for better visibility on search engines.                        </li>
                        <li className="text-lg">
                            <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                            <strong>Revising texts </strong>: reorganization for effective presentation on the web.
                        </li>
                    </ul>
                </div>
                <div className="flex flex-col md:flex-row justify-center items-start space-y-8 md:space-y-0 md:space-x-6 my-10">
                    {packs.map((pack, index) => (
                    <div
                        key={index}
                        className={`relative flex-grow h-full ${
                        index === Math.floor(packs.length / 2)
                            ? 'bg-[#29426d] text-white'
                            : 'bg-gray-200'
                        } shadow-lg rounded-lg p-6 w-full md:w-1/3 min-h-[650px] group overflow-hidden transition-transform duration-300 ease-in-out transform hover:bg-[#29426d]`}
                    >
                        <div className="relative z-10 text-left h-full flex flex-col justify-between">
                        <div>
                            <h2 className={`pt-2 pb-2 text-2xl font-semibold bg-[#face30] inline-block px-3 py-1 rounded w-full text-center transition-transform duration-300 ease-in-out transform group-hover:-translate-y-3 ${
                            index === Math.floor(packs.length / 2) ? 'text-[#29426d]' : ''
                            }`}>
                            {pack.title}
                            </h2>

                            <div className="text-center my-3 transition-transform duration-300 ease-in-out transform group-hover:-translate-y-3">
                                <span className="text-4xl font-bold group-hover:text-white">{pack.price}</span>
                                <span className={index === Math.floor(packs.length / 2) ? "text-lg text-white" : "text-lg text-black group-hover:text-white"}>{pack.currency}</span>
                            </div>

                            <div className="text-center my-3 transition-transform duration-300 ease-in-out transform group-hover:-translate-y-3">
                                <span className="text-4xl font-bold group-hover:text-white">{pack.price2}</span>
                                <span className={index === Math.floor(packs.length / 2) ? "text-lg text-white" : "text-lg text-black group-hover:text-white"}>{pack.currency2}</span>
                            </div>

                            <ul className="my-6 list-disc list-inside space-y-3">
                            {pack.advantages.map((advantage, i) => (
                                <li key={i} className={`${
                                index === Math.floor(packs.length / 2)
                                    ? 'text-white'
                                    : 'text-gray-700 group-hover:text-white'
                                } transition-colors duration-300`}>
                                {advantage}
                                </li>
                            ))}
                            </ul>
                        </div>

                        <p className={`text-center ${
                            index === Math.floor(packs.length / 2)
                            ? 'text-white'
                            : 'text-green-500 group-hover:text-white'
                        } mt-4 transition-colors duration-300`}>
                            {pack.maintenance}
                        </p>

                        <div className="text-center mt-4">
                            <a href="#contact">
                                <button className={`${
                                index !== Math.floor(packs.length / 2) ? 'text-whie group-hover:text-white' : 'text-white'
                                } bg-custom-yellow animate-bounce font-bold py-2 px-4 rounded transition-transform duration-300 hover:text-white hover:scale-105`}>
                                Order
                                </button>
                            </a>
                        </div>
                        </div>
                    </div>
                    ))}
                </div>
            </section>

            <section id="contact" className=" bg-image mx-auto px-4 py-12">
                    <h2 className="text-4xl font-bold mt-4 mb-8 text-custom-blue text-center relative z-10">Contact-us</h2>
               {flashMessage &&
                    <div className="flex justify-center relative z-10">
                        <div className="alert max-w-4xl mx-auto space-y-4 text-center">
                            <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                            {flashMessage}
                        </div>
                    </div>
                   }
               <form onSubmit={handleSubmit} className="max-w-4xl mx-auto space-y-4">
                <div className="flex space-x-4 mb-4">
                    <div className="flex-1">
                        <label className="block mb-2">Lastname</label>
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <div className="flex-1">
                        <label className="block mb-2">Firstname</label>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                </div>
                <div className="flex space-x-4 mb-4">
                    <div className="flex-1">
                        <label className="block mb-2">E-mail</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <div className="flex-1">
                        <label className="block mb-2">WhatsApp Number</label>
                        <input
                            type="text"
                            name="whatsapp"
                            value={formData.whatsapp}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                            placeholder="Entrez le numéro +indicatif du pays"
                            required
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block mb-2">Subject</label>
                    <select
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                        required
                    >
                        <option value="">Select a topic</option>
                        <option value="">Request a quote</option>
                        <option value="Pack 1">Starter Pack</option>
                        <option value="Pack 2">Pro Pack</option>
                        <option value="Pack 3">Premium Pack</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block mb-2">Message</label>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                        required
                    ></textarea>
                </div>

                {/* ReCAPTCHA */}
                <div className="mb-4">
                    <ReCAPTCHA
                        sitekey="6LeJeV4qAAAAAN6Wg1EtMVS6cqqu1l3SvzZ21GcG"
                        onChange={handleCaptcha}
                    />
                </div>

                <button type="submit" className="bg-custom-blue text-white py-2 px-4 rounded-md hover:bg-blue-700 transition">
                    Send
                </button>
               </form>
            </section>
            <FooterEn />
        </div>
    );
};

export default Hero;
