import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Typewriter from '../components/Typewriter';
import logo from '../assets/images/criospulse.webp';
import poster1 from '../assets/images/poster1.webp';
import poster2 from '../assets/images/poster2.webp';
import myImage from '../assets/images/hero.webp';
import myImage2 from '../assets/images/bg-droite-scaled.webp';
// start section picture
import structure from '../assets/images/Structure.webp';
import conception from '../assets/images/Conception.webp';
import exploration from '../assets/images/Exploration.webp';
import integration from '../assets/images/Integration.webp';
import maintenance from '../assets/images/Maintenance.webp';
import optimisation from '../assets/images/Optimisation.webp';
import miseEnLigne from '../assets/images/MiseEnLigne.webp';
import lancement from '../assets/images/Lancement.webp';
// end section picture
import Modal from '../components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';
import { faLink, faCheck, faPaintBrush, faCompass ,faChartLine,faPlay,faWrench, faLaptopCode, faSearch, faMobileAlt, faHeadset, faLightbulb, faDollarSign, faTools, faRocket } from '@fortawesome/free-solid-svg-icons';
import { Timeline, Bookmark } from "../vertical-progress-timeline";
import "../vertical-progress-timeline/style.css";
import ReCAPTCHA from 'react-google-recaptcha';
import Footer from "./Footer";
import AOS from 'aos';
import 'aos/dist/aos.css';


const movies = [
    {
      title: "Crios Consulting",
      description: "Gestion de démarches administratives et d'externalisation",
      poster: poster1,
      url: "https://criosconsulting.com",
    },
    {
      title: "Dedravo Express",
      description: "Envoi de colis entre Cotonou et Paris ainsi que pour les achats en ligne sur tous les sites européens",
      poster: poster2,
      url: "https://dedravoexpress.com",
    },

  ];
  const typeWriter = ["Collaborative", "Créative", "Abordable", "Professionnelle"];

const HamburgerMenu = ({ onToggle }) => {


    return (
        <div className="absolute top-4 right-4 z-20 cursor-pointer mt-3" onClick={onToggle}>
            <div className="bg-white h-1 w-10 mb-2"></div>
            <div className="bg-white h-1 w-10 mb-2 mr-2 ml-2"></div>
            <div className="bg-white h-1 w-10"></div>
        </div>
    );
};




const packs = [
    {
      id : 1,
      title: 'Pack STARTER',
      price: '150 000',
      reference: 'A1B2C',
      currency: ' FCFA',
      price2: '230',
      currency2: ' €',
      duration: '',
      advantages: [
        'Une grande page',
        '5 photos libres de droits',
        'Analyse et suivi du trafic (Google Analytics, etc.)',
        'Garantie de stabilité',
        'Mises à jour de sécurité',
        'Rapports annuels',
      ],
    //   maintenance: 'Maintenance : 100 000 Fcfa / an',
    },
    {
    id : 2,
      title: 'Pack PRO',
      price: '800 000',
      reference: 'D3E4F',
      currency: ' FCFA',
      price2: '1260',
      currency2: ' €',
      duration: '',
      advantages: [
        'Environ 5 pages',
        '7 photos libres de droits',
        'Analyse et suivi du trafic (Google Analytics, etc.)',
        'Backups réguliers',
        'Garantie de stabilité',
        'Mises à jour de sécurité',
        'Rapports mensuels',
      ],
      maintenance: 'Maintenance : 200 000 Fcfa / an',
    },
    {
    id : 3,
      title: 'Pack PREMIUM',
      price: '1 200 000',
      reference: 'G5H6I',
      currency: ' FCFA',
      price2: '1890',
      currency2: ' €',
      duration: '',
      advantages: [
        'Environ 5-10 pages + blog',
        '10 photos libres de droits',
        'Analyse et suivi du trafic (Google Analytics, etc.)',
        'Backups réguliers',
        'Garantie de stabilité',
        'Mises à jour de sécurité',
        'Rapports mensuels',
        'Veille de position Google',
      ],
      maintenance: 'Maintenance : 300 000 Fcfa / an',
    },
  ];
  

const Hero = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(prev => !prev);
    };


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        whatsapp: '',
        subject: '',
        message: '',
    });
    const [flashMessage, setFlashMessage] = useState('');
    const [captchaToken, setCaptchaToken] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!captchaToken) {
            alert('Veuillez compléter la vérification reCAPTCHA.');
            return;
        }

        try {
            const response = await fetch('https://criospulse.com/app/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de l\'envoi des données');
            }

            const data = await response.json();
            // alert(data.firstName);
            setFlashMessage(data.flash_message);
            // Réinitialiser le formulaire si nécessaire
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                whatsapp: '',
                subject: '',
                message: '',
            });
            setCaptchaToken(null);
        } catch (error) {
            setFlashMessage('Une erreur est survenue : ' + error.message);
        }
    };


    const handleCaptcha = (token) => {
        setCaptchaToken(token);
    };

    
    useEffect(() => {
        AOS.init({
          once: false,
        });
    }, []);


    return (
        <div className="relative h-screen bg-cover bg-center" style={{ backgroundImage: `url(${myImage})` }}>
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <HamburgerMenu onToggle={toggleMenu} />
            <div className="flex flex-col items-center justify-center h-full relative z-10 text-white">
            <div className="flex flex-col items-center justify-center mx-4">
                    <img src={logo} alt="Logo" className="mb-4 h-48 md:h-54"/>
                    <div className="text-4xl md:text-5xl text-center">
                        <h1 className="text-5xl md:text-6xl font-bold mb-4">
                            Agence Web{" "}
                            <span className="text-custom-yellow">
                                <Typewriter data={typeWriter} />
                            </span>
                        </h1>
                    </div>
            </div>

                <p className="text-3xl mt-4 md:text-2xl ml-4">Concevez des sites internet d'exception à un coût maîtrisé</p>

                <a href="https://criospulse.com/app/order?packRef=A1B2C" style={{ marginTop: '20px', textDecoration: 'none' }}>
                    <button
                        style={{
                        backgroundColor: '#e30f0f',
                        color: '#fff',
                        fontSize: '20px',
                        padding: '20px 40px',
                        borderRadius: '40px',
                        border: 'none',
                        cursor: 'pointer',
                        fontWeight: '1300',
                        textTransform: 'uppercase',
                        letterSpacing: '0.5px',
                        animation: 'blink 1.5s infinite', // Animation clignotante
                        }}
                    >
                        Accéder à la vente flash
                    </button>
                </a>


                <a href="#home" className="ml-4 inline-block animate-bounce ">
                    <div className="flex items-center justify-center w-16 h-16 border-4 border-custom-yellow rounded-full mt-8 mb-8">
                        <FontAwesomeIcon icon={faLongArrowAltDown} className="text-custom-yellow h-8" />
                    </div>
                </a>
            </div>
            <Modal isOpen={menuOpen} onClose={toggleMenu} />

            {/* Section Agence Web */}
            <section id="home" className="flex items-center justify-center py-10" style={{ backgroundImage: `url(${myImage2})` }}>
                <div className="text-center container px-4">
                    <h2 className="text-4xl font-bold mt-8 text-custom-blue">Quels avantages de faire créer votre site web chez nous?</h2>
                    <p className="mt-4 text-xl mx-auto text-justify">
                        Faire appel à notre agence pour la création de votre site web, c'est bénéficier d'une expertise pointue et d'un accompagnement personnalisé. Notre équipe de professionnels passionnés met à votre disposition des compétences variées en design, développement et marketing. Nous croyons en la création de solutions sur mesure, adaptées aux besoins spécifiques de chaque client. Grâce à notre approche collaborative, nous prenons le temps de comprendre vos objectifs et votre vision, afin de vous livrer un site qui reflète parfaitement l'identité de votre marque.
                        <br /><br />
                        De plus, nous garantissons une optimisation continue pour les moteurs de recherche, ce qui est essentiel pour assurer la visibilité de votre site. Nos designs responsives garantissent une expérience utilisateur fluide sur tous les appareils, qu'il s'agisse d'un ordinateur de bureau ou d'un smartphone. En choisissant notre agence, vous bénéficiez également d'un support après-vente réactif, vous permettant de vous concentrer sur votre activité pendant que nous veillons à ce que votre site reste performant et à jour. Avec nous, vous n’obtenez pas seulement un site web, mais un véritable partenaire dans votre réussite digitale.
                    </p>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-8">
                        <div className="flex flex-col items-center icon-animation">
                            <FontAwesomeIcon icon={faLaptopCode} className="text-custom-yellow h-16" />
                            <h3 className="mt-2 text-lg font-semibold">Développement sur mesure</h3>
                        </div>
                        <div className="flex flex-col items-center icon-animation">
                            <FontAwesomeIcon icon={faSearch} className="text-custom-yellow h-16" />
                            <h3 className="mt-2 text-lg font-semibold">SEO Optimisé</h3>
                        </div>
                        <div className="flex flex-col items-center icon-animation">
                            <FontAwesomeIcon icon={faMobileAlt} className="text-custom-yellow h-16" />
                            <h3 className="mt-2 text-lg font-semibold">Design Responsive</h3>
                        </div>
                        <div className="flex flex-col items-center icon-animation">
                            <FontAwesomeIcon icon={faHeadset} className="text-custom-yellow h-16" />
                            <h3 className="mt-2 text-lg font-semibold">Support Client</h3>
                        </div>
                        <div className="flex flex-col items-center icon-animation">
                            <FontAwesomeIcon icon={faLightbulb} className="text-custom-yellow h-16" />
                            <h3 className="mt-2 text-lg font-semibold">Solutions Innovantes</h3>
                        </div>
                        <div className="flex flex-col items-center icon-animation">
                            <FontAwesomeIcon icon={faDollarSign} className="text-custom-yellow h-16" />
                            <h3 className="mt-2 text-lg font-semibold">Tarifs Compétitifs</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section id="sites" class="bg-custom-gray py-16" >
                <h2 className="text-4xl font-bold mt-8 text-custom-blue text-center mb-12">Explorez nos <span className="text-custom-yellow">exceptionnelles réalisations</span></h2>
                <div className="align-items-center">
                    <div className="wrapper">
                    {movies.map((movie, index) => (
                        <div className="card" key={index}>
                        <div className="poster">
                            <img src={movie.poster} alt={movie.title} />
                        </div>
                        <div className="details">
                            <h1>{movie.title}</h1>

                            <p className="desc">{movie.description}</p>
                            <div className="cast">
                               <a key={index} href={movie.url} target="_blank" rel="noopener noreferrer">
                                    <button className="border-2 border-custom-yellow rounded-lg py-2 px-4 bg-transparent text-custom-yellow flex items-center text-lg">
                                        <FontAwesomeIcon icon={faLink} className="mr-2" />
                                        Voir le site
                                    </button>
                                </a>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
                </div>
            </section>


            <section id="methodologie" class="bg-custom-gray py-16" >
                <h2 className="text-4xl font-bold mt-8 text-custom-blue text-center mb-12">Découvrez notre <span className="text-custom-yellow"> méthodologie</span> de conception de site web</h2>
                <div className="align-items-center">
                    <Timeline className="my-vertical-progress">
                    <Bookmark data-aos="flip-down" icon={<FontAwesomeIcon icon={faSearch} className="timeline-icone" />}>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">Analyse des besoins</h1>
                        <p className="text-xl">Pour concevoir un site web, il est essentiel de définir clairement vos attentes. Cela débute par une réunion pour cerner vos besoins. Nous y traiterons des services requis, du cadre du projet, de son thème, du public cible, des délais envisagés, des restrictions, ainsi que des concurrents, entre autres.</p>
                    </Bookmark>
                    <Bookmark data-aos="flip-down" data-aos-offset="100" data-aos-delay="100" icon={<FontAwesomeIcon icon={faLightbulb} className="timeline-icone"/>}>
                        <div className="marginPicOnDesktop hidden md:block" style={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={structure} className="w-60" style={{ width: '80%' }} alt="structure"/>
                        </div>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">Structure des contenus</h1>
                        <p className="text-xl">Une fois les besoins bien définis, CriosPulse examine attentivement la documentation fournie par le Client. Charte graphique, description des services, articles de presse, tous les éléments sont étudiés et analysés par CriosPulse, qui propose ensuite une arborescence du contenu accompagnée de ses recommandations.</p>
                    </Bookmark>
                    <Bookmark data-aos="flip-down" data-aos-offset="100" data-aos-delay="100" icon={<FontAwesomeIcon icon={faPaintBrush} className="timeline-icone"/>}>
                        <div className="marginPicOnDesktop hidden md:block" style={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={conception} className="w-60" style={{ width: '80%' }} alt="conception"/>
                        </div>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">Conception de la maquette</h1>
                        <p className="text-xl">CriosPulse présente au Client plusieurs exemples de maquettes de sites web en responsive design, adaptées au secteur d’activité et au type de site souhaité. Le Client choisit le modèle qui lui convient, en exprimant ses préférences quant à l’agencement des éléments.</p>
                    </Bookmark>
                    <Bookmark data-aos="flip-down" data-aos-offset="100" data-aos-delay="100" icon={<FontAwesomeIcon icon={faTools} className="timeline-icone" />}>
                        <div className="marginPicOnDesktop hidden md:block" style={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={miseEnLigne} className="w-60" style={{ width: '80%' }} alt="miseEnLigne"/>
                        </div>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">Mise en ligne</h1>
                        <p className="text-xl">Une fois les choix cruciaux du Client confirmés, CriosPulse entame l'installation technique du site. Cela inclut le nom de domaine, l'hébergement, les adresses e-mail, le certificat SSL, la base de données, le système de gestion de contenu, ainsi que les modules de sécurité et de maintenance. À la fin de cette étape, les adresses e-mail sont opérationnelles et le site présente le logo du Client avec la mention « site en construction ».</p>
                    </Bookmark>
                    <Bookmark data-aos="flip-down" data-aos-offset="100" data-aos-delay="100" icon={<FontAwesomeIcon icon={faRocket} className="timeline-icone" />} >
                        <div className="marginPicOnDesktop hidden md:block" style={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={integration} className="w-60" style={{ width: '80%' }} alt="integration"/>
                        </div>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">L'intégration</h1>
                        <p className="text-xl">L’intégration représente la phase où CriosPulse va conférer au site vide la présentation choisie lors de la sélection de la maquette. C’est au cours de cette étape que les contenus (textes, images, etc.) seront formatés pour créer un ensemble harmonieux et agréable à naviguer.</p>
                    </Bookmark>
                    <Bookmark data-aos="flip-down" data-aos-offset="100" data-aos-delay="100" icon={<FontAwesomeIcon icon={faCompass} className="timeline-icone" />}>
                        <div className="marginPicOnDesktop hidden md:block" style={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={exploration} className="w-60" style={{ width: '80%' }} alt="exploration"/>
                        </div>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">L'exploration</h1>
                        <p className="text-xl">Une fois l'intégration achevée, CriosPulse crée un accès sécurisé pour le Client, lui offrant ainsi la possibilité d'explorer le site web. Cela lui permet de se familiariser avec son outil et de faire part de ses retours à CriosPulse pour de possibles ajustements sur le contenu ou le design.</p>
                    </Bookmark>
                    <Bookmark data-aos="flip-down" data-aos-offset="100" data-aos-delay="100" icon={<FontAwesomeIcon icon={faChartLine} className="timeline-icone"/>}>
                        <div className="marginPicOnDesktop hidden md:block" style={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={optimisation} className="w-60" style={{ width: '80%' }} alt="optimisation"/>
                        </div>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">L'optimisation</h1>
                        <p className="text-xl">Pour assurer un bon positionnement du site web sur les moteurs de recherche, il est crucial de porter une attention spécifique à son optimisation pour le SEO. Cela commence par des améliorations techniques (structure du code, rapidité de chargement, taille des pages, compression des images, etc.), suivies d’une optimisation du contenu (titres, mots-clés, balises méta, etc.).</p>
                    </Bookmark>
                    <Bookmark data-aos="flip-down" data-aos-offset="100" data-aos-delay="100" icon={<FontAwesomeIcon icon={faPlay} className="timeline-icone"/>}>
                        <div className="marginPicOnDesktop hidden md:block" style={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={lancement} className="w-60" style={{ width: '80%' }} alt="lancement"/>
                        </div>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">Le lancement</h1>
                        <p className="text-xl"> Une date est fixée pour l'inauguration officielle du site web. Celui-ci devient accessible au public et est soumis aux moteurs de recherche pour son référencement. Une annonce promotionnelle est publiée sur la page Facebook de Criospulse, incluant un lien vers le site. Pour terminer, un plan du site est également envoyé à Google afin de faciliter son indexation.</p>
                    </Bookmark>
                    <Bookmark data-aos="flip-down" data-aos-offset="100" data-aos-delay="100" icon={<FontAwesomeIcon icon={faWrench} className="timeline-icone" />}>
                        <div className="marginPicOnDesktop hidden md:block" style={{ display: 'flex', justifyContent: 'center'}}>
                            <img src={maintenance} className="w-60" style={{ width: '80%' }} alt="maintenance"/>
                        </div>
                        <h1 className="text-3xl mb-2 font-bold text-custom-blue">La maintenance</h1>
                        <p className="text-xl">Pour assurer la fiabilité du site web et la protection de ses utilisateurs, Criospulse propose un service de maintenance technique et de sécurité de manière régulière.</p>
                    </Bookmark>

                    </Timeline>
                </div>
            </section>

            <section id="tarifs" className="bg-custom-gray mx-auto px-4 py-12">
                <h2 className="text-4xl font-bold mt-4 mb-8 text-custom-blue text-center">Consultez nos différents packs</h2>
                <div className=" container p-6 mx-auto my-6 bg-white rounded-lg shadow-lg overflow-hidden">
                    <h2 className="text-2xl font-bold text-left text-[#29426d] mb-4">Quels sont les principaux services inclus dans les packs de Crios Pulse ?</h2>
                    <ul className="list-none space-y-2 md:text-2xl">
                        <li className="text-lg">
                            <FontAwesomeIcon icon={faCheck} className="text-custom-yellow mr-2" />
                            <strong>Site web complet </strong> : nom de domaine, hébergement et comptes emails.
                        </li>
                        <li className="text-lg">
                            <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                            <strong>Design responsive </strong> : parfaitement adapté à tous les appareils, y compris les mobiles.
                        </li>
                        <li className="text-lg">
                            <FontAwesomeIcon icon={faCheck} className="text-custom-yellow mr-2" />
                            <strong>Optimisation SEO (référencement naturel) </strong> :  pour une meilleure visibilité sur les moteurs de recherche.
                        </li>
                        <li className="text-lg">
                            <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                            <strong>Révision des textes </strong> :  réorganisation pour une présentation efficace sur le web.
                        </li>
                    </ul>
                </div>
                <div className="flex flex-col md:flex-row justify-center items-start space-y-8 md:space-y-0 md:space-x-6 my-10">
                    {packs.map((pack, index) => (
                        <div
                        key={index}
                        className={`relative flex-grow h-full ${
                            index === Math.floor(packs.length / 2)
                            ? 'bg-[#29426d] text-white'
                            : 'bg-gray-200'
                        } shadow-lg rounded-lg p-6 w-full md:w-1/3 min-h-[650px] group overflow-hidden transition-transform duration-300 ease-in-out transform hover:bg-[#29426d] flex flex-col justify-between`}
                        >
                        <div className="relative z-10 text-left flex-grow h-full flex flex-col justify-between">
                            <div>
                            <h2
                                className={`pt-2 pb-2 text-2xl font-semibold bg-[#face30] inline-block px-3 py-1 rounded w-full text-center transition-transform duration-300 ease-in-out transform group-hover:-translate-y-3 ${
                                index === Math.floor(packs.length / 2) ? 'text-[#29426d]' : ''
                                }`}
                            >
                                {pack.title}
                            </h2>

                            <div className="text-center my-3 transition-transform duration-300 ease-in-out transform group-hover:-translate-y-3">
                                <span className="text-4xl font-bold group-hover:text-white">
                                {pack.price}
                                </span>
                                <span
                                className={
                                    index === Math.floor(packs.length / 2)
                                    ? 'text-lg text-white'
                                    : 'text-lg text-black group-hover:text-white'
                                }
                                >
                                {pack.currency}
                                </span>
                            </div>

                            <div className="text-center my-3 transition-transform duration-300 ease-in-out transform group-hover:-translate-y-3">
                                <span className="text-4xl font-bold group-hover:text-white">
                                {pack.price2}
                                </span>
                                <span
                                className={
                                    index === Math.floor(packs.length / 2)
                                    ? 'text-lg text-white'
                                    : 'text-lg text-black group-hover:text-white'
                                }
                                >
                                {pack.currency2}
                                </span>
                            </div>

                            <ul className="my-6 list-disc list-inside space-y-3">
                                {pack.advantages.map((advantage, i) => (
                                <li
                                    key={i}
                                    className={`${
                                    index === Math.floor(packs.length / 2)
                                        ? 'text-white'
                                        : 'text-gray-700 group-hover:text-white'
                                    } transition-colors duration-300`}
                                >
                                    {advantage}
                                </li>
                                ))}
                            </ul>
                            </div>

                            <div className="mt-auto">
                            <p
                                className={`text-center ${
                                index === Math.floor(packs.length / 2)
                                    ? 'text-white'
                                    : 'text-green-500 group-hover:text-white'
                                } mt-4 transition-colors duration-300`}
                            >
                                {pack.maintenance}
                            </p>

                            <div className="text-center mt-4">
                                {/* <a href="#contact"> */}
                                <a href={`https://criospulse.com/app/order?packRef=${pack.reference}`}>
                                <button
                                    className={`${
                                    index !== Math.floor(packs.length / 2)
                                        ? 'text-white group-hover:text-white'
                                        : 'text-white'
                                    } bg-custom-yellow animate-bounce font-bold py-2 px-4 rounded transition-transform duration-300 hover:text-white hover:scale-105`}
                                >
                                    Commander
                                </button>
                                </a>
                            </div>
                            </div>
                        </div>
                        </div>
                    ))}
                </div>
            </section>

            <section id="contact" className="bg-custom-gray mx-auto px-4 py-12 bg-image">
               <h2 className="text-4xl font-bold mt-4 mb-8 text-custom-blue text-center">Contactez-nous</h2>
               {flashMessage &&
                    <div className="flex justify-center">
                        <div className="alert max-w-4xl mx-auto space-y-4 text-center">
                            <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                            {flashMessage}
                        </div>
                    </div>
                   }
               <form onSubmit={handleSubmit} className="max-w-4xl mx-auto space-y-4">
                <div className="flex space-x-4 mb-4">
                    <div className="flex-1">
                        <label className="block mb-2">Nom</label>
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <div className="flex-1">
                        <label className="block mb-2">Prénom</label>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                </div>
                <div className="flex space-x-4 mb-4">
                    <div className="flex-1">
                        <label className="block mb-2">E-mail</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <div className="flex-1">
                        <label className="block mb-2">Numéro WhatsApp</label>
                        <input
                            type="text"
                            name="whatsapp"
                            value={formData.whatsapp}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                            placeholder="Entrez le numéro +indicatif du pays"
                            required
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block mb-2">Sujet</label>
                    <select
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                        required
                    >
                        <option value="">Sélectionner un sujet</option>
                        <option value="Pack 1">Pack Starter</option>
                        <option value="Pack 2">Pack Pro</option>
                        <option value="Pack 3">Pack Premium</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block mb-2">Message</label>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                        required
                    ></textarea>
                </div>
                
                {/* ReCAPTCHA */}
                <div className="mb-4">
                    <ReCAPTCHA
                        sitekey="6LeJeV4qAAAAAN6Wg1EtMVS6cqqu1l3SvzZ21GcG"
                        onChange={handleCaptcha}
                    />
                </div>

                <button type="submit" className="bg-custom-blue text-white py-2 px-4 rounded-md hover:bg-blue-700 transition">
                    Envoyer
                </button>
               </form>
            </section>
            <Footer />
        </div>
    );
};

export default Hero;
